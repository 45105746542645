@import 'fonts';

body {
  padding: 0;
  margin: 0;
  background: #fff;
  font-family: 'Montserrat';
  overflow-x: hidden;
  overflow-y: auto;
}

ul,
li {
  list-style-type: none;
  padding: 0;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

h1 {
  margin: 0;
  font-size: 1rem;
}

h2,
h3,
h4,
h5,
p {
  margin: 0;
  //font-size: 1rem;
}

a {
  color: inherit;
  text-decoration: none;
  text-decoration-skip-ink: none;
}

.container {
  max-width: 1225px;
  width: 100%;
  margin: 0 auto;
}

.hidden {
  opacity: 0;
  height: 0;
  line-height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.hero {
  // height: calc(100vh - 120px); // Subtract 120px from the viewport height
  // min-height: 480px; // Set a minimum height to ensure it doesn't get too small on short screens
  // position: relative;
  // overflow: hidden;

  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgb(255, 255, 255) 0%,
    rgb(157, 195, 234) 40%,
    rgb(1, 71, 167) 100%
  );
  background-repeat: no-repeat;
  //background-size: cover;
  font-family: 'Playfair Display', sans-serif;
  overflow: hidden;
  padding-bottom: 0;
  position: relative;

  &__title {
    text-align: center;
    color: #fff;
    font-size: 50px;
    position: relative;
    margin-bottom: 30px;

    svg {
      position: absolute;
      right: 24px;
      top: 43px;
    }
  }

  .container {
    z-index: 10;
    position: relative;
  }

  .bg {
    position: absolute !important;
    bottom: 0;
    left: -300px;
    z-index: -1 !important;
    width: auto; // Allow the image to maintain its original width
    height: auto; // Maintain aspect ratio
    max-height: none; // Remove max-height constraint

    @media screen and (max-width: 650px) {
      width: 600px;
      left: -150px;
      //bottom: 100px;
    }
  }

  &__info-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    //margin-bottom: 25px;
    width: 190px;
    margin: 0 auto;

    p {
      width: 100%;
      font-weight: 500;
      color: #fff;
      font-size: 12px;
      font-family: 'Fira Sans', sans-serif;
      color: #132648;

      span {
        font-size: 32px;
        font-weight: 500;
      }

      &::after {
        // position: absolute;
        // content: '';
        // height: 1px;
        // width: 110px;
        // background: #fff;
        // left: 0;
        // top: 10px;
      }

      &::before {
        // position: absolute;
        // content: '';
        // height: 1px;
        // width: 110px;
        // background: #fff;
        // left: 0;
        // bottom: 10px;
      }
    }

    button {
      display: flex;
      gap: 2px;
      width: 80px;
      height: 80px;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      font-weight: 700;
      font-size: 18px;
      background: rgb(255, 255, 255);
      background: linear-gradient(158deg, rgba(255, 255, 255, 1) 0%, rgba(165, 188, 189, 1) 100%);
      -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
      box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
      cursor: pointer;

      &::after {
        position: absolute;
        content: '';
        height: 1px;
        width: 30px;
        background: #fff;
        right: -25px;
        top: 10px;
      }

      &::before {
        position: absolute;
        content: '';
        height: 1px;
        width: 30px;
        background: #fff;
        right: -25px;
        bottom: 10px;
      }

      svg {
        width: 20px;
      }
    }
  }

  &__info {
    padding: 20px 7px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-size: 17px;
      color: #132648;
      font-weight: 600;
      text-align: center;
      font-family: 'Hind', sans-serif;
      line-height: 120%;
    }

    button {
      width: 50%;
      margin-top: 16px;
      padding: 12px 0;
      border-radius: 6px;
    }
  }

  &__title {
    img {
      width: 100%;
    }
  }

  //cannot make &__content for some reasons - or buttons fall
  .hero__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    min-height: 40vh;
    padding: 22px 16px 200px 50px;

    > * {
      z-index: 100;
      position: relative;
    }

    > svg {
      text-align: center;
      width: 175px;
      display: block;
      position: absolute;
      top: 70px;
      left: -180px;

      @media screen and (max-width: 700px) {
        position: relative;
        left: 0;
        top: 0;
      }
    }

    .info__block {
      position: relative;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: 700px;
      padding: 35px 87px;
      background: rgba(53, 82, 100, 0.46);
      height: calc(100svh - 465px);
      backdrop-filter: blur(10px);
      max-height: 635px;
      min-height: 584px;
      color: #fff;
      border-radius: 50px 50px 0 0;

      .block__title {
        font-size: 26px;
        margin-bottom: 55px;
        font-weight: 700;
      }

      .block__description {
        width: 100%;
        font-size: 20px;
        line-height: 120%;
        margin-bottom: 52px;
      }

      .block__main {
        width: 100%;
        max-width: 420px;
        margin-left: 35px;
        margin-bottom: 50px;
      }

      .hero__buttons {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .intro__btn {
          height: 55px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          font-family: Montserrat;
          border-radius: 30px;
          background: #009cff;
          color: #fff;
          font-weight: 700;
          max-width: 178px;
          width: 100%;
        }
      }
    }

    &__overlay {
      position: absolute;
      top: 460px; // Adjust this value to position the overlay above the buttons
      left: 50px; // Match left padding of hero__content
      right: 16px; // Match right padding of hero__content
      background-color: rgba(255, 255, 255, 0.7);
      padding: 10px 10px;
      border-radius: 10px;
      text-align: center;
      z-index: 101; // Ensure it's above other content

      p {
        color: #000;
        font-size: 16px;
        font-weight: 600;
        line-height: 120%;
        font-family: 'Hind', sans-serif;
        margin: 0;
      }
    }
  }

  //New buttons
  &__buttons-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
    transition: all 0.3s ease;

    &.stuck {
      position: fixed;
      top: 0;
      bottom: auto;
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      padding: 5px; // Reduce padding when stuck
    }
  }

  .map__btn,
  .route__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 12px;
    border-radius: 6px;
    font-family: 'Hind', sans-serif;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    white-space: nowrap;
    line-height: 1;
    width: 140px;

    .stuck & {
      padding: 8px 20px; // Reduce padding when stuck
      font-size: 16px; // Optionally reduce font size
    }
  }

  .map__btn {
    background: #864a73;
    color: #fff;

    &:hover {
      background: #864a73;
    }
  }

  .route__btn {
    background: #c85416;
    color: #fff;
    box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);

    &:hover {
      background: #c85416;
    }
  }
}

// Add button styles for when scrolling
.scrolled .hero__buttons-container {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.landing {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  &__wrapper {
    overflow-y: auto;
    min-height: 200%; // Adjust 80px to match your categories bar height
  }

  &__card {
    padding-bottom: 66px;
  }

  .card_wrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px; // This gap will be consistent both horizontally and vertically
    justify-content: center;
    padding: 20px;
  }

  &__item {
    max-width: 312px;
    height: 400px;
    overflow: hidden;

    h3 {
      font-family: 'Marcellus', serif;
      font-size: 22px;
      text-align: center;
      margin-bottom: 16px;
    }

    a {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center 80%;
      }
    }

    &-img {
      overflow: hidden;
      width: 100%;
      height: 176px;
      margin-bottom: 16px;
      border-radius: 8px;
    }

    p {
      font-size: 18px;
      line-height: 120%;
      font-family: 'Hind', sans-serif;
    }
  }

  //The version of the card for tours as opposed to objects
  //(longer titles, extra fields, etc.)
  &__item_tour {
    width: 360px;
    height: 100%; // Reduced overall height
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; // Align items to the top
    //margin-bottom: 20px; // Add space between cards if they're in a grid
    border-bottom: 1px solid black;

    h3 {
      font-family: 'Marcellus', serif;
      font-size: 18px;
      text-align: center;
      margin: 0;
      padding: 10px 5px 0px 5px; // Reduced padding
      width: 100%;
      height: 40px; // Slightly reduced height
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    a {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      color: inherit;
    }

    &-img {
      width: 100%;
      margin-bottom: 5px; // Reduced margin
      border-radius: 8px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center 80%;
        border-radius: 4px 4px 0 0;
        margin-bottom: 2px;
        max-width: 360px;
        max-height: 270px;
        min-width: 360px;
        min-height: 270px;
      }

      .separator {
        width: 2px;
        background: white;
        margin: 3px 0px 3px 0px;
      }

      .tour-info-extended {
        height: 34px !important;
      }

      .tour-info {
        width: 100%;
        height: 26px;
        border-radius: 0px 0px 8px 8px;
        background-color: rgb(209, 209, 209);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-sizing: border-box;
        // Match the bottom corners of the image

        .rating {
          border-bottom-right-radius: 10px;
        }

        .info-section {
          display: flex;
          border-bottom-left-radius: 10px;
          flex: 1;
          align-items: center;
          justify-content: center;
          font-family: 'Hind', serif;
          font-size: 18px;
          color: #333;
          background-color: rgb(209, 209, 209);

          .icon {
            margin-right: 3px;
            font-size: 14px; // Size for Material Icons
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 2px; //for centering

            &.euro {
              color: #3e9c35;
            }

            &.rating {
              color: #ffa534;
            }
          }

          .value {
            font-weight: 600;
            color: #333; // Default color for all values
          }

          &.price .value {
            color: #3e9c35; // Red color only for the price value
          }
        }

        .now-empty {
          width: 18px; // Placeholder width for the empty section
        }
      }
    }

    p {
      font-size: 14px; // Slightly reduced font size
      line-height: 1.3;
      font-family: 'Hind', sans-serif;
      text-align: celeftnter;
      width: 100%;
      padding: 0 5px; // Reduced padding
      margin: 0;
      margin-bottom: 8px;
      flex-grow: 1;
      display: flex;
      align-items: flex-start; // Align text to the top
      overflow: hidden; // Hide overflow if text is too long
    }
  }
}

@media (max-width: 1680px) {
  .landing {
    .card_wrapper {
      display: flex;
      align-items: end;
      justify-content: center;
    }
  }
}

@media (max-width: 1180px) {
  .landing .card_wrapper {
    justify-content: center;
  }
}

@media (max-width: 950px) {
  .landing .card_wrapper {
    margin-right: 0;
  }
}

.landing-no__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  svg {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 620px) {
  .card {
    &_wrapper {
      padding: 0 20px;
    }
  }
}

.filter__img {
  width: 30px;
}

div.strikethrough{
  width: 35px;
  display: flex;
  margin: auto;
}

.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 40%;
  right: 0;
  border-top: 2px solid;
  border-color: black;
  
  -webkit-transform:rotate(-15deg);
  -moz-transform:rotate(-15deg);
  -ms-transform:rotate(-15deg);
  -o-transform:rotate(-15deg);
  transform:rotate(-15deg);
}

.view__title > a {
  text-decoration: underline;
  text-decoration-color: #D76A00;
}

// .special *, *::before, *::after {
//   -webkit-box-sizing:unset;
// }

// .special-button-circle {
//   border: solid 1px white;
//   position: absolute;
//   border-radius: 50%;
//   width: 100px;
//   height: 100px;
//   left: 4px;
//   top: 4px;
// }

// .special {
//   margin-top: 30px;
//   margin-bottom: 20px;
//   display: flex;
//   justify-content: center;
// }
// .special-div {
//   display: flex;
//   width: 426px;
// }
// .special-bar {
//   display: flex;
//   width: 100%;
//   background-color: #3072cb;
//   z-index: -1;
//   border-radius: 10px;
//   border: solid 1px white;
//   height: 78px;
//   margin-top: 10px;
// }
// .special-offer {
//   width: 105px;
// }
// .special-offer p {
//   font-family: "Fira Sans Extra Condensed", sans-serif;
//   font-size: 34px;
//   line-height: 34px;
//   color: white;
//   margin-left: 10px;
//   margin-top: 5px;
//   margin-bottom: 8px;
//   margin-right: 5px;
// }
// .special-text {
//   width: 210px;
//   margin-left: 10px;
//   margin-top: 5px;
//   margin-bottom: 5px;
// }
// .special-text p {
//   font-family: "Fira Sans Extra Condensed", sans-serif;
//   font-size: 20px;
//   line-height: 22px;
//   color: white;
//   margin: 0px;
// }
// .special-button-overlay {
//   width: 426px;
//   position: absolute;
// }
// .special-button-div {
//   width: 110px;
//   position: absolute;
//   right: 0px;
// }
// .special-button-div a {
//   text-decoration: none;
// }
// .special-button {
//   border-radius: 50%;
//   background-color: #00aded;
//   width: 100px;
//   height: 100px;
//   align-content: center;
//   text-align: center;
// }
// .special-button span {
//   font-family: "Fira Sans Extra Condensed", sans-serif;
//   color: white;
//   text-decoration: none;
//   font-size: 28px;
//   line-height: 28px;
//   font-weight: 500;
// }
// .special-button-circle {
//   border: solid 1px white;
//   position: absolute;
//   border-radius: 50%;
//   width: 90px;
//   height: 90px;
//   left: 4px;
//   top: 4px;
// }
// @media (max-width: 500px) {
//   .special-div {
//     width: 330px;
//   }
//   .special-bar {
//     height: 58px;
//   }
//   .special-offer {
//     width: 80px;
//   }
//   .special-offer p {
//     font-size: 24px;
//     line-height: 24px;
//     color: white;
//     margin-left: 5px;
//     margin-top: 5px;
//     margin-bottom: 5px;
//     margin-right: 3px;
//   }
//   .special-text {
//     width: 150px;
//     margin-left: 2px;
//     margin-top: 5px;
//     margin-bottom: 5px;
//   }
//   .special-text p {
//     font-size: 16px;
//     line-height: 18px;
//   }
//   .special-button-overlay {
//     width: 330px;
//     position: absolute;
//   }
//   .special-button-div {
//     width: 90px;
//     position: absolute;
//     right: 0px;
//   }
//   .special-button {
//     border-radius: 50%;
//     background-color: #00aded;
//     width: 80px;
//     height: 80px;
//     align-content: center;
//     text-align: center;
//   }
//   .special-button span {
//     color: white;
//     text-decoration: none;
//     font-size: 22px;
//     line-height: 22px;
//     font-weight: 500;
//   }
//   .special-button-circle {
//     border: solid 1px white;
//     position: absolute;
//     border-radius: 50%;
//     width: 70px;
//     height: 70px;
//     left: 4px;
//     top: 4px;
//   }        
// } /*media max-width:500px*/
// .i-choose-this-free-for-you {
//   margin-top: 30px;
//   margin-bottom: 20px;
//   display: flex;
//   justify-content: center;
// }
// .i-choose-this-free-for-you-div {
//   display: flex;
//   width: 426px;
// }
// .i-choose-this-free-for-you-bar {
//   display: flex;
//   width: 100%;
//   background-color: #3072cb;
//   z-index: -1;
//   border-radius: 10px;
//   border: solid 1px white;
//   height: 78px;
//   margin-top: 10px;
// }
// .i-choose-this-free-for-you-button-overlay {
//   width: 426px;
//   position: absolute;
// }
// .i-choose-this-free-for-you-button-div {
//   width: 110px;
//   position: absolute;
//   right: 0px;
// }
// .i-choose-this-free-for-you-button-div a {
//   text-decoration: none;
// }
// .i-choose-this-free-for-you-button {
//   border-radius: 50%;
//   background-color: #00aded;
//   width: 100px;
//   height: 100px;
//   align-content: center;
//   text-align: center;
// }
// .i-choose-this-free-for-you-button span {
//   font-family: "Fira Sans Extra Condensed", sans-serif;
//   color: white;
//   text-decoration: none;
//   font-size: 25px;
//   line-height: 25px;
//   font-weight: 500;
// }      
// .i-choose-this-free-for-you-bar span {
//   font-family: "Fira Sans Extra Condensed", sans-serif;
//   font-size: 34px;
//   line-height: 34px;
//   color: white;
//   margin-left: 60px;
//   margin-top: 22px;
//   margin-bottom: 22px;
//   margin-right: 5px;
// }
// .i-choose-this-free-for-you-button-circle {
//   border: solid 1px white;
//   position: absolute;
//   border-radius: 50%;
//   width: 90px;
//   height: 90px;
//   left: 4px;
//   top: 4px;
// }
// @media (max-width: 500px) {
//   .i-choose-this-free-for-you-div {
//     width: 330px;
//   }
//   .i-choose-this-free-for-you-bar {
//     height: 58px;
//   }
//   .i-choose-this-free-for-you-bar span {
//     font-family: "Fira Sans Extra Condensed", sans-serif;
//     font-size: 34px;
//     line-height: 34px;
//     color: white;
//     margin-left: 35px;
//     margin-top: 12px;
//     margin-bottom: 12px;
//     margin-right: 5px;
//   }
//   .i-choose-this-free-for-you-button-overlay {
//     width: 330px;
//     position: absolute;
//   }
//   .i-choose-this-free-for-you-button-div {
//     width: 90px;
//     position: absolute;
//     right: 0px;
//   }
//   .i-choose-this-free-for-you-button {
//     border-radius: 50%;
//     background-color: #00aded;
//     width: 80px;
//     height: 80px;
//     align-content: center;
//     text-align: center;
//   }
//   .i-choose-this-free-for-you-button span {
//     color: white;
//     text-decoration: none;
//     font-size: 22px;
//     line-height: 22px;
//     font-weight: 500;
//   }
//   .i-choose-this-free-for-you-button-circle {
//     border: solid 1px white;
//     position: absolute;
//     border-radius: 50%;
//     width: 70px;
//     height: 70px;
//     left: 4px;
//     top: 4px;
//   }        
// } /*media max-width:500px*/

// .special {
//         margin-top: 30px;
//         margin-bottom: 20px;
//         display: flex;
//         justify-content: center;
//       }
//       .special-continue {
//         position: absolute;
//         width: 426px;
//         color: black;
//         border-radius: 10px 10px 0 0;
//         border: solid 1px black;
//         border-bottom: 0px;
//         height: 38px;
//       }
//       .special-continue p {
//         font-family: "Fira Sans Extra Condensed", sans-serif;
//         color: black;
//         font-size: 26px;
//         line-height: 26px;
//         margin-left: 10px;
//         margin-top: 7px;
//         margin-bottom: 5px;
//         margin-right: 5px;
//       }
//       .special-div {
//         display: flex;
//         width: 426px;
//       }
//       .special-bar {
//         display: flex;
//         width: 100%;
//         background-color: #3072cb;
//         z-index: -1;
//         border-radius: 0 0 10px 10px;
//         border: solid 1px white;
//         height: 78px;
//         margin-top: 38px;
//       }
//       .special-offer {
//         width: 105px;
//       }
//       .special-offer p {
//         font-family: "Fira Sans Extra Condensed", sans-serif;
//         font-size: 34px;
//         line-height: 34px;
//         color: white;
//         margin-left: 10px;
//         margin-top: 5px;
//         margin-bottom: 8px;
//         margin-right: 5px;
//       }
//       .special-text {
//         width: 210px;
//         margin-left: 10px;
//         margin-top: 5px;
//         margin-bottom: 5px;
//       }
//       .special-text p {
//         font-family: "Fira Sans Extra Condensed", sans-serif;
//         font-size: 20px;
//         line-height: 22px;
//         color: white;
//         margin: 0px;
//       }
//       .special-button-overlay {
//         width: 426px;
//         position: absolute;
//       }
//       .special-button-div {
//         width: 110px;
//         position: absolute;
//         right: 0px;
//         top: 30px;
//       }
//       .special-button-div a {
//         text-decoration: none;
//       }
//       .special-button {
//         border-radius: 50%;
//         background-color: #00aded;
//         width: 100px;
//         height: 100px;
//         align-content: center;
//         text-align: center;
//       }
//       .special-button-circle {
//         border: solid 1px white;
//         position: absolute;
//         border-radius: 50%;
//         width: 90px;
//         height: 90px;
//         left: 5px;
//         top: 5px;
//       }
//       .special-button span {
//         font-family: "Fira Sans Extra Condensed", sans-serif;
//         color: white;
//         text-decoration: none;
//         font-size: 28px;
//         line-height: 28px;
//         font-weight: 500;
//       }
//       @media (max-width: 500px) {
//         .special-continue {
//           width: 330px !important;
//         }
//         .special-div {
//           width: 330px;
//         }
//         .special-bar {
//           height: 58px;
//         }
//         .special-offer {
//           width: 80px;
//         }
//         .special-offer p {
//           font-size: 24px;
//           line-height: 24px;
//           color: white;
//           margin-left: 5px;
//           margin-top: 5px;
//           margin-bottom: 5px;
//           margin-right: 3px;
//         }
//         .special-text {
//           width: 150px;
//           margin-left: 2px;
//           margin-top: 5px;
//           margin-bottom: 5px;
//         }
//         .special-text p {
//           font-size: 16px;
//           line-height: 18px;
//         }
//         .special-button-overlay {
//           width: 330px;
//           position: absolute;
//         }
//         .special-button-div {
//           width: 90px;
//           position: absolute;
//           right: 0px;
//         }
//         .special-button {
//           border-radius: 50%;
//           background-color: #00aded;
//           width: 80px;
//           height: 80px;
//           align-content: center;
//           text-align: center;
//         }
//         .special-button-circle {
//           width: 72px;
//           height: 72px;
//           left: 4px;
//           top: 4px;
//         }
//         .special-button span {
//           color: white;
//           text-decoration: none;
//           font-size: 22px;
//           line-height: 22px;
//           font-weight: 500;
//         }
//       } /*media max-width:500px*/
