@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Marcellus&display=swap');

.home {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 48px);
  overflow: hidden;
}

.categories {
  width: 100%;
  height: 80px;
  /* Fallback for browsers that don't support calc() */
  height: calc(80px + env(safe-area-inset-bottom));
  background: #224a77;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: env(safe-area-inset-bottom);

  .container {
    width: 100%;
    max-width: 600px;
    height: 100%;
    padding: 6px 15px;
    padding-bottom: calc(6px + env(safe-area-inset-bottom));
    display: flex;
    align-items: center;
  }

  &__carousel {
    width: 100%;
    height: 100%;

    &-list {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 2px 5px;
    }

    &-item {
      flex: 1; // Make each item take equal width
      height: 100%; //calc(100% - 4px); // Full height minus 2pt top and bottom
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s ease;
      position: relative;
      padding: 2px 5px;


      &.active {
        &::before {
          content: '';
          position: absolute;
          width: 78px;
          height: calc(100% + 10px);
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 8px;
          border: 2px solid #fff;
          pointer-events: none;
        }
      }

    }

    &-locate {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;

      svg {
        height: 24px;
        width: auto;
        max-width: 100%; // Ensure SVG doesn't overflow
        margin: 0 auto 5px;

        * {
          transition: 0.15s;
        }
      }
    }

    &-content {
      color: #fff;
      font-size: 12px; // Slightly smaller font size
      font-style: normal;
      font-weight: 500;
      line-height: 1.2;
      transition: 0.15s;
      text-align: center;
      white-space: nowrap; // Prevent text from wrapping
      overflow: hidden;
      text-overflow: ellipsis; // Add ellipsis for overflowing text
      max-width: 100%; // Ensure text doesn't overflow
    }
  }
}

.categories__carousel-item.active .categories__carousel-locate {
  box-shadow: none;
}

.categories__carousel-item:not(.active) {
  svg * {
    fill: #a7caf2;
  }

  .categories__carousel-content p {
    color: #a7caf2;
  }
}

@media (max-width: 319px) {
  .categories {
    &__carousel {
      &-item {
        padding: 3px;

        &.active {
          &::before {
            width: calc(100% + 10px); // Fixed width when there's enough space
          }
        }
      }

      &-content {
        font-size: 10px; // Even smaller font for very narrow screens
      }

      svg {
        height: 20px; // Slightly smaller SVG for very narrow screens
      }
    }
  }
}

.checkBox {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  width: 100%;

  p {
    line-height: 100%;
  }

  .box {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 2px solid #D3DEE2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    cursor: pointer;
  }
}

.clusterIcon {
  img {
    display: none;
  }

  width: 40px !important;
  height: 40px !important;

  div {
    width: 40px !important;
    height: 40px !important;
    position: relative !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: #fff !important;
    background: #316096;
    border-radius: 100% !important;
    border: 3px solid #fff !important;
  }
}

.sheet {
  position: fixed;
  width: 100svw;
  height: 100svh;
  inset: 0;
  transition: .3s;
  transform: translateY(200%);
  pointer-events: none;
  z-index: 100;
  background: #fff;
  padding: 60px 20px 20px;
  font-size: 18px;
  line-height: 120%;
  font-weight: 500;
  overflow-y: auto;

  &.active {
    transform: translateY(0);
    pointer-events: all;
  }

  &:not(.active) {
    overflow: hidden;
    visibility: hidden;
    overflow-y: auto;
    visibility: visible;
  }

  .routes__close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
  }

  .agr__title {
    margin-bottom: 25px;
    font-family: "Marcellus", serif;
    font-size: 30px;
    text-align: center;
  }

  .rules {
    margin-top: 40px;
    padding: 0 10px;

    div {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      margin-bottom: 20px;
    }
  }
}

.route-swiper {
  .swiper-container {
    position: relative;
  }

  .swiper {
    position: relative;
  }

  [class^="swiper-button-prev"],
  [class^="swiper-button-next"] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 44px;
    height: 44px;
    background: none;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 10;

    &:hover {
      opacity: 1;
    }

    &.swiper-button-disabled {
      opacity: 0.35;
      cursor: auto;
      pointer-events: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 44px;
      height: 44px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 0.7;
      filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.7)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.7)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.7));
    }
  }

  [class^="swiper-button-prev"] {
    left: 20px;

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 24 24' fill='none' stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M15 18l-6-6 6-6'/%3E%3C/svg%3E");
    }
  }

  [class^="swiper-button-next"] {
    right: 20px;

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 24 24' fill='none' stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M9 18l6-6-6-6'/%3E%3C/svg%3E");
    }
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    max-height: 80vh; // Maintain maximum height
    height: auto; // Allow height to adjust based on content

    .routes__item-image {
      width: 100%;
      max-height: 60vh;
      object-fit: contain;
      flex: 1 1 auto; // Allow image to grow and shrink
      min-height: 0; // Ensure image can shrink below its natural size if needed
    }

    .routes__item-descr {
      padding: 10px;
      overflow-y: auto;
      flex: 0 1 auto; // Allow description to shrink if needed
      max-height: 20vh;
    }

    &.slide {
      display: flex;
      flex-direction: column;
    }
  }
}

.share-buttons {
  display: table;
}

.share-buttons > span {
  display: table-cell;
  vertical-align: middle;
  padding-right: 4px;
}

.share-button {
  position: relative !important;
  display: inline-block !important;
  padding-right: 3px !important;
}

.share-button-copy-link {
  width: 35px;
  height: 35px;
  position: relative !important;
  background: rgb(208, 208, 208) !important;
  padding: 0px !important;
  display: inline-block !important;
  border-radius: 50%;
  padding-right: 3px !important;
}

.audio-header {
  padding-bottom: 4px;
}

.map {
  flex: 1;
  position: relative;
  min-height: 0;
  /* This is crucial for flexbox to work correctly */

  height: calc(100vh - 130px);
  // height: calc(100svh - 130px);

  // margin: 0 0 12px;
  .outsideClick {
    position: fixed;
    width: 100svw;
    height: 100svh;
    inset: 0;
    background: transparent;
    z-index: 10;
  }

  .buy_modal {
    position: fixed;
    height: 100svh;
    transform: translateY(100%);
    pointer-events: none;
    transition: .5s;
    background: #fff;
    z-index: 20;
    border-radius: 30px 30px 0 0;
    padding-top: 42px;
    padding-left: 25px;
    padding-right: 25px;
    max-width: 400px;
    left: calc((100svw - 400px) / 2);
    width: 100%;
    max-height: 230px;
    bottom: 0;
    box-shadow: 0 4px 4px 0 rgba(97, 97, 97, 0.53);

    @media screen and (max-width: 480px) {
      left: 0;
      margin: 0;
    }

    &.active {
      transform: translateY(0);
      pointer-events: all;
    }

    .routes__close {
      position: absolute;
      top: 10px;
      right: 10px;
      background: transparent;
    }

    .buy_title {
      background: rgba(255, 172, 91, 0.7);
      font-size: 18px;
      padding: 12px;
      border-radius: 8px;
      width: fit-content;
      margin: 0 auto 30px;
      font-weight: 500;
      text-align: center;
    }

    .buy_text {
      font-size: 20px;
      margin-bottom: 30px;
      font-weight: 500;
      text-align: center;
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 24px;

      button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        background: #3E94F8;
        font-weight: 600;
        font-size: 18px;
        box-shadow: 0 4px 4px 0 rgba(97, 97, 97, 0.53);
        color: #fff;
        text-transform: uppercase;
        border-radius: 6px;
      }

      .close {
        background: #576369;
      }
    }
  }

  .description_modal {
    position: fixed;
    // height: 100svh;
    transform: translateY(100%);
    pointer-events: none;
    transition: .5s;
    background: #fff;
    z-index: 110;
    border-radius: 30px 30px 0 0;
    padding-top: 42px;
    padding-left: 25px;
    padding-right: 25px;
    max-width: 400px;
    left: calc((100svw - 400px) / 2);
    width: 100%;
    max-height: 520px;
    bottom: 0;
    box-shadow: 0 4px 4px 0 rgba(97, 97, 97, 0.53);

    @media screen and (max-width: 480px) {
      left: 0;
      margin: 0;
    }

    &.active {
      transform: translateY(0);
      pointer-events: all;
    }

    .routes__close {
      position: absolute;
      top: 10px;
      right: 10px;
      background: transparent;
    }

    .description_title {
      text-align: center;
      margin-bottom: 16px;
      font-family: "Marcellus", serif;
      font-size: 21px;
    }

    .description_text {
      border-radius: 10px;
      padding: 12px;
      // background: rgba(191, 234, 121, 0.7);
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 25px;
      line-height: 120%;
      // text-align: center;
      overflow-y: scroll;
      max-height: 440px;
    }

    .description_text strong {
      font-weight: bold;
    }

    .description_text ul {
      padding-left: 10px;
    }

    .description_text ul li {
      list-style-type: disclosure-closed;
    }

    .close {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      background: #3E94F8;
      font-weight: 600;
      font-size: 18px;
      box-shadow: 0 4px 4px 0 rgba(97, 97, 97, 0.53);
      color: #fff;
      text-transform: uppercase;
      border-radius: 6px;
      max-width: 100px;
      margin: 0 auto;
    }
  }

  .thanks_modal {
    position: fixed;
    height: 100svh;
    transform: translateY(100%);
    pointer-events: none;
    transition: .5s;
    background: #fff;
    z-index: 110;
    border-radius: 30px 30px 0 0;
    padding-top: 42px;
    padding-left: 25px;
    padding-right: 25px;
    max-width: 400px;
    left: calc((100svw - 400px) / 2);
    width: 100%;
    max-height: 320px;
    bottom: 0;
    box-shadow: 0 4px 4px 0 rgba(97, 97, 97, 0.53);

    @media screen and (max-width: 480px) {
      left: 0;
      margin: 0;
    }

    &.active {
      transform: translateY(0);
      pointer-events: all;
    }

    .routes__close {
      position: absolute;
      top: 10px;
      right: 10px;
      background: transparent;
    }

    .buy_title {
      text-align: center;
      margin-bottom: 16px;
      font-family: "Marcellus", serif;
      font-size: 21px;
    }

    .buy_text {
      border-radius: 10px;
      padding: 12px;
      background: rgba(191, 234, 121, 0.7);
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 25px;
      line-height: 120%;
      text-align: center;
    }

    .close {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      background: #3E94F8;
      font-weight: 600;
      font-size: 18px;
      box-shadow: 0 4px 4px 0 rgba(97, 97, 97, 0.53);
      color: #fff;
      text-transform: uppercase;
      border-radius: 6px;
      max-width: 100px;
      margin: 0 auto;
    }
  }

  .routes__window {
    position: fixed;
    height: 100svh;
    border: 1px solid rgba(0, 0, 0, 0.25);
    transform: translateY(100%);
    pointer-events: none;
    transition: .5s;
    background: #fff;
    z-index: 10;
    border-radius: 30px 30px 0 0;
    padding-top: 42px;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 500px;
    left: calc((100svw - 500px) / 2);
    width: 100%;
    max-height: 900px;
    bottom: 0;
    box-shadow: 0 4px 4px 0 rgba(97, 97, 97, 0.53);

    @media screen and (max-width: 480px) {
      left: 0;
      margin: 0;
      border: 1px solid rgba(0, 0, 0, 0);
    }

    &.active {
      transform: translateY(0);
      pointer-events: all;
    }


    .routes__close {
      position: absolute;
      top: 10px;
      right: 10px;
      background: transparent;
    }

    .routes__title {
      text-align: center;
      font-family: "Marcellus", serif;
      font-weight: 400;
      font-size: 22px;
      max-width: 80%;
      margin: 0 auto 24px;
    }

    .screen__route {
      width: 100%;
      margin: 24px 0;
    }

    .info__block {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 51px;
      background: rgba(255, 172, 91, 0.7);
      border-radius: 10px;
      font-size: 18px;
      font-family: "Hind", sans-serif;
    }

    .routes__back {
      position: absolute;
      top: 10px;
      left: 10px;
      background: transparent;
    }

    .routes__categories {
      display: flex;
      gap: 15px;
      width: 100%;
      overflow-x: auto;
      padding-bottom: 10px;

      button {
        padding: 8px 12px;
        text-wrap: nowrap;
        background: transparent;
        border: 1px solid #DDE5EA;
        border-radius: 4px;
        transition: .15s;

        &.active {
          border-color: #D76A00;
        }
      }
    }

    .routes {
      overflow-y: auto;
      margin-top: 10px;
      padding-top: 10px;
      height: 85%;

      .routes__item {
        margin-bottom: 50px;
      }

      .routes__item-title {
        font-size: 22px;
        font-family: "Marcellus", serif;
        margin-bottom: 8px;
      }

      .routes__item-time {
        font-size: 12px;
        //font-family: "Marcellus", serif;
        //margin-bottom: 6px;
      }

      .routes__view_route {
        font-size: 12px;
        color: #fc5d08;
        cursor: pointer;
      }

      .routes__view_route_text {
        padding-left: 5px;
        font-weight: bold;
        display: inline-block;
      }

      .description_button {
        color: #2980b9;
        cursor: pointer;
      }

      .tour_description {
        margin-top: 5px;
        display: inline;
        line-height: 18px;
      }

      .routes__item-dot-title {
        cursor: pointer;
      }

      .routes__item-image {
        overflow: hidden;
        border-radius: 10px 10px 0px 0px;
        //height: 168px;
        object-fit: cover;
        object-position: center;
        width: 100%;
        margin-top: 16px;
        margin-bottom: 3px;
        &.screen {
          height: 325px;
        }
      }

      .routes__item-descr {
        font-size: 18px;
        line-height: 120%;
        margin: 16px 0;
      }
    }

    .view__title {
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      margin-top: 25px;
      margin-bottom: 10px;

      span {
        color: #D76A00;
      }
    }

    .view__coordinates {
      text-align: center;
      margin-bottom: 10px;
      color: #D76A00;
      font-weight: bold;
    }

    .view__wrap {
      display: flex;
      flex-direction: column;
      padding: 0;
      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: hidden;
      height: 82%;
      position: relative;
    }

    .view__wrap .google-map-container {
      left: -20px;
      right: -20px;
      width: calc(100% + 40px);
    }

    .view__wrap .other-content {
      padding: 0 20px;
    }

    .view__route {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      background: #3E94F8;
      font-weight: 600;
      font-size: 18px;
      box-shadow: 0 4px 4px 0 rgba(97, 97, 97, 0.53);
      color: #fff;
      text-transform: uppercase;
      border-radius: 6px;
      margin-top: 16px;
    }

    .view__route__more {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      background: rgb(209, 209, 209);
      font-weight: 600;
      font-size: 18px;
      box-shadow: 0 4px 4px 0 rgba(97, 97, 97, 0.53);
      color: #fff;
      text-transform: uppercase;
      border-radius: 6px;
      margin-top: 16px;
    }
  }

  .routes__item {
    &-dotsNames {
      display: flex;
      flex-wrap: wrap;
      margin-top: 12px;
      font-size: 25px;
      font-weight: 500;
    }

    &-column {
      width: 50%;
      display: flex;
      flex-direction: column;

      @media (max-width: 320px) {
        width: 100%;
      }
    }

    &-dot-title {
      margin-right: 5px;
      margin-bottom: 5px;
      font-size: 14px;
      display: flex;
      align-items: center;

      span {
        margin-right: 5px;
        color: #C0CBD0;
      }
    }
  }

  .slide {
    height: 325px;
  }

  .route__control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;

    .to_routes {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 18px;
      font-weight: 600;
      color: #316096;
      background: transparent;
    }

    .control__flex {
      display: flex;
      align-items: center;
      gap: 16px;

      .prev {
        font-weight: 600;
        font-size: 18px;
        color: #fff;
        background: #576369;
        box-shadow: 0 4px 4px 0 rgba(97, 97, 97, 0.53);
        border-radius: 6px;
        padding: 12px 20px;
      }

      .next {
        font-weight: 600;
        font-size: 18px;
        color: #fff;
        background: #3E94F8;
        box-shadow: 0 4px 4px 0 rgba(97, 97, 97, 0.53);
        border-radius: 6px;
        text-transform: uppercase;
        padding: 12px 20px;
      }
    }
  }

  .select_object {
    position: fixed;
    max-width: 500px;
    width: calc(100% - 20px); // Leave small space on sides for mobile
    background: #fff;
    z-index: 100; //keep on top
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    border-radius: 30px 30px 0 0;
    padding: 20px;
    transition: transform 0.3s ease-out;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &.active {
      transform: translateX(-50%) translateY(0);
    }

    &.low {
      height: 155px;
    }

    &.middle {
      max-height: 70vh;
    }

    &.high {
      height: 80vh;
      max-height: 80vh;
    }

    // Mobile-specific adjustments
    @media screen and (max-width: 480px) {
      padding: 15px; // Smaller padding on mobile

      .object__name {
        margin: 15px 30px 10px; // Adjusted margins for mobile
        font-size: 20px; // Slightly smaller font on mobile
      }

      .object__description {
        font-size: 16px; // Smaller font on mobile
        margin-bottom: 20px;
      }

      // Adjust button positions for mobile
      .object_close,
      .change__view {
        top: 10px;
      }

      .object_close {
        right: 15px;
      }

      .change__view {
        left: 15px;
      }
    }

    // Styles for wider screens
    @media screen and (min-width: 481px) {
      left: 50%; // Center on wider screens
      transform: translateX(-50%) translateY(100%);

      &.active {
        transform: translateX(-50%) translateY(0);
      }
    }

    .object__name {
      margin: 20px 40px 10px; // Top, Right/Left, Bottom
      text-align: center;
      margin-bottom: 10px;
    }

    .object__photo {
      width: 100%;
      //max-height: 50vh; // Adjust this value as needed
      min-height: 0;
      object-fit: contain;
      flex-grow: 1;
      flex: 0 1 auto; // Allow image to shrink, but not grow beyond its size
      //max-height: calc(100% - 155px); // Adjust based on other elements' heights
      margin-bottom: 12px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
    }

    .object_close {
      position: absolute;
      top: 16px;
      right: 22px;
      background: transparent;
    }

    .change__view {
      position: absolute;
      top: 16px;
      left: 22px;
      background: transparent;
    }

    .object__name {
      text-align: center;
      font-family: "Marcellus", serif;
      font-weight: 400;
      font-size: 22px;
      margin-bottom: 26px;

      span {
        color: #D76A00;
      }
    }

    .object__description {
      font-size: 18px;
      font-family: "Hind", sans-serif;
      margin-bottom: 6px;
      font-weight: 400;
      line-height: 1.2em;
      transition: max-height 0.3s ease-out;

      &.closed {
        // Now done in JS
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &.opened {
        // Now done in JS
        // max-height: none;
        overflow: visible;
      }
    }

    .description-wrap {
      position: relative;
      padding-bottom: 20px; // Space for the button

      button {
        position: absolute;
        background: #fff;
        bottom: 0;
        right: 0;
        color: #006DEA;
        display: flex;
        gap: 4px;
        align-items: center;
        font-size: 18px;
        padding: 5px 10px;
        cursor: pointer;
        transition: opacity 0.3s ease;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    audio {
      width: 100%;
    }
  }

  .excursions__list-btn {
    position: absolute;
    bottom: 30px;
    right: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    z-index: 10;
    padding: 10px;
    background: #fff;
    color: #000;
    border-radius: 100%;
    box-shadow: 0 4px 4px 0 rgba(97, 97, 97, 0.53);
  }

  .excursionModal {
    position: absolute;
    z-index: 10;
    height: 100%;
    inset: 0;
    width: 100%;
    background: #00000065;
    transition: 0.15s;
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;

    .wrap {
      display: flex;
      align-items: center;
      gap: 30px;
      overflow-x: auto;
      padding: 25px;
    }

    &__close {
      position: absolute;
      right: 25px;
      top: 25px;
      background: transparent;

      @media screen and (max-width: 640px) {
        right: 5px;
        top: 5px;
      }

      svg {
        width: 40px;
        height: 40px;

        @media screen and (max-width: 640px) {
          width: 30px;
          height: 30px;
        }
      }
    }

    .excursion {
      width: 25%;
      border-radius: 8px;
      overflow: hidden;
      min-width: 300px;

      @media screen and (max-width: 940px) {
        width: 50%;
      }

      @media screen and (max-width: 640px) {
        width: 100%;
      }

      &__top {
        padding: 10px;
        background: rgb(150, 194, 222);
        color: #fff;
        font-size: 24px;
      }

      &__points {
        padding: 10px;
        background: #fff;

        .excursion__point {
          position: relative;
          margin-bottom: 10px;
          margin-left: 10px;

          &::before {
            position: absolute;
            content: '';
            width: 5px;
            height: 5px;
            background: #000;
            border-radius: 100%;
            top: 5px;
            left: -10px;
          }

          p {
            font-size: 16px;
            font-weight: 500;
          }
        }
      }

      &__start {
        width: 100%;
        padding: 15px;
        background: #eea764;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  .startedExcursion__top {
    display: flex;
    position: absolute;
    inset: 0;
    height: fit-content;
    width: fit-content;
    gap: 10px;
    padding-left: 10px;
    padding-top: 10px;

    .startedExcursion__select {
      display: flex;
      align-items: center;
      gap: 10px;
      background: #eea764;
      color: #fff;
      font-size: 16px;
      padding: 12px;
      width: 250px;
      height: 52px;
      justify-content: center;
      border-radius: 8px;
    }

    .startedExcursion__select-container {
      padding: 15px;
      background: #fff;
      border-radius: 8px;
      margin-top: 10px;

      .checkbox {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background: #d6d6d6;
        border: 2px solid transparent;

        &.checked {
          background: #eea764;
        }

        &.active {
          border-color: #eea764;
        }
      }

      .next__step-btn {
        width: 100%;
        padding: 15px;
        background: #eea764;
        color: #fff;
        font-size: 16px;
        border-radius: 8px;
        margin-top: 20px;
      }

      .startedExcursion__select-point {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 500;
      }
    }

    .startedExcursion__name {
      background: #26a69a;
      border-radius: 8px;
      width: 250px;
      color: #fff;
      font-size: 16px;
      height: 52px;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .rightSide {
    height: fit-content;
  }
}

._xHshk,
._KVwkC {
  z-index: 200 !important;
}

._YZRPO,
._xlcmb {
  padding: 0 !important;
}

._7vsRM {
  background: #fff !important;
  box-shadow: none !important;
}

._MY77B {
  width: 66px !important;

  path {
    fill: #457595 !important;
    width: 66px !important;
  }
}

.rss-header {
  background-color: #fff !important;
}

.rss-content {
  background: rgb(255, 255, 255) !important;
  box-shadow: none !important;

  .excursion__start {
    width: 100%;
    background: #eea764;
    color: #fff;
    font-weight: 600;
    border-radius: 16px;
    font-size: 14px;
    padding: 10px;
    margin-bottom: 20px;

    &:disabled {
      opacity: 0.7;
    }
  }
}

.rss-footer {
  background: #a7d1ed !important;
  box-shadow: none !important;
}

.rss-content__closeBtn {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  z-index: 10;
  padding: 14px 27px;
  background: #fff;
  color: #000;
  border-radius: 16px;
  box-shadow: 0 4px 4px 0 rgba(97, 97, 97, 0.53);
}

.rss-content__tour-block {
  padding: 12px 15px;
  border-radius: 6px;
  background: #4E80A0;
  color: #fff;
  font-size: 18px;
  line-height: 120%;
  margin-bottom: 10px;
  position: relative;

  >p {
    margin-right: 50px;
  }

  >span {
    padding: 5px;
    background: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  >span svg {

    width: 24px;
    height: 24px;
  }

  .tour-block__bottom {
    p {
      font-size: 12px;
      font-weight: 400;
    }

    display: flex;
    align-items: center;
    margin-top: 15px;
    justify-content: space-between;

    svg {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 60px;
    }
  }
}


.categories-excursion {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow-x: auto;
  background: transparent;
  scrollbar-width: none;
  /* "auto" или "thin"  */
  scrollbar-color: transparent transparent;

  button {
    text-wrap: nowrap;
    padding: 5px 20px;
    font-size: 14px;
    color: #C2C0C0;
    border-color: #C2C0C0;
    border: 1px solid;
    background: transparent;
    border-radius: 8px;

    &.active {
      color: #000;
      background: #C4E8FF;
      border-color: transparent;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

.point-card {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  background: #DBF1FF;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  width: 100%;

  img {
    width: 125px;
    height: 125px;
    object-fit: cover;
    border-radius: 10px;
  }

  p {
    font-size: 13px;
  }

  .card-info__title {
    margin-bottom: 10px;
    font-size: 16px;
  }
}

.point {
  display: flex;
  align-items: center;
  gap: 5px;

  .point-number {
    position: relative;
    min-width: 37px;
    height: 37px;
    border-radius: 100%;
    background: #fff;
    color: #C0C0C0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      position: absolute;
      left: 17px;
      top: 60px;
    }

    &.active {
      border: 4px solid #D2741A;
    }
  }
}

.no__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  background: #cecece;
  margin-top: 10px;

  svg {
    width: 30px;
    height: 30px;
  }
}

.mapMark {
  padding: 1px;
  background: white;
  max-height: 260px;
  max-width: 220px;
  /*!!ISA*/
  /*
  max-height: 260px;
  max-width: 220px;
  overflow-y: auto;
  */
  overflow: hidden;

  &__head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75em;

    audio {
      width: '100%';
      //max-width: 250px;
      max-height: 18px;
    }
  }

  &__title {
    padding-right: 10px;
    font-weight: 400;
    font-size: 1rem;
  }

  &__image {
    flex: 0 0 33.3%;
    max-width: 240px;
    margin-bottom: 6px;
    padding-right: 10px;

    img {
      max-width: 100%;
    }
  }

  &__description {
    flex: 1 1 auto;
  }
}

.card .card-image img {
  max-height: 260px;
}

.catalog {
  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    font-size: 14px;
  }

  &__head-stats {
    margin-left: auto;
    font-size: 14px;
    text-align: right;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.75em 1.5em;

    @include r($xl) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include r($sm) {
      grid-template-columns: 1fr;
    }
  }

  &__col {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    min-width: 1px;

    .card {
      flex: 1 0 auto;
      width: 100%;
      cursor: pointer;

      &-action {
        audio {
          width: 100%;
          //max-width: 250px;
          font-size: 12px;
        }
      }

      &-no__image {
        width: 100%;
        height: 200px;
        background: #c9c9c9;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}

.map-compass {
  position: absolute;
  overflow: hidden;
  display: flex;
  right: 60px;
  bottom: 24px;
  z-index: 9;
  flex-direction: column;
  width: 80px;
  height: 80px;
  background: rgba(#fff, 0.2);
  border: 2px solid rgba(#fff, 0.6);
  border-radius: 50%;
  transition: transform 0.3s linear;
  perspective-origin: 50% 50%;
  transform-origin: 50% 50%;
  user-select: none;

  &__wrapper {
    position: relative;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }
}

.compass {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  transform: translate(-50%, -50%);

  &__south {
    border-top: 25px solid #2980b9;
    border-left: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid transparent;
  }

  &__north {
    border-top: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 25px solid #c0392b;
    border-right: 8px solid transparent;

    &::after {
      position: absolute;
      content: 'N';
      top: 20px;
      left: 50%;
      font-weight: 500;
      font-size: 10px;
      color: #fff;
      transform: translateX(-50%);
    }
  }

  &__marks {
    width: 100%;
    height: 100%;
    font-size: 0;
    pointer-events: none;
    transition: transform 0.3s linear;
    perspective-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  &__mark {
    position: absolute;
    font-size: 0;

    // background: #666;

    i {
      font-weight: 700;
      font-size: 9px;
      font-style: normal;
    }

    &._n,
    &._s {
      left: 50%;

      // width: 1px;
      // height: 10px;
      transform: translateX(-50%);
    }

    &._n {
      top: 1px;
    }

    &._s {
      bottom: 1px;
    }

    &._e,
    &._w {
      top: 50%;

      // width: 10px;
      // height: 1px;
      transform: translateY(-50%);
    }

    &._e {
      right: 2px;
    }

    &._w {
      left: 2px;
    }
  }
}

.settings-cta {
  margin-top: 32px;
}

@keyframes FirstTimePopup__fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes FirstTimePopup__fadeOut {
  from {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }

  to {
    opacity: 0;
    transform: translateX(-50%) translateY(20px);
  }
}

.first-time-popup {
  position: fixed;
  bottom: 85px; // 5px above the 80px controls
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 400px; // Never wider than 400px
  background-color: #FFF4B9; // Exact yellow from the original SVG
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: FirstTimePopup__fadeIn 1s ease-out;

  p {
    margin: 0;
    text-align: center;
    font-size: 16px;
    color: #171819; // Dark text color for contrast
    font-weight: 500;
    line-height: 1.5;
  }
}

.feedback {
  width: 100svw;
  height: 100svh;
  inset: 0;
  background: transparent;
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  pointer-events: none;

  .review-modal {
    position: relative;
    bottom: 0;
    background: #fff;
    max-width: 360px;
    width: 100%;
    padding: 0 24px 24px;
    border-radius: 30px 30px 0 0;
    max-height: 0;
    transition: .3s;
    visibility: hidden;

    h4 {
      margin-top: 45px;
      font-size: 21px;
      font-family: "Marcellus", serif;
    }
  }

  &.active {
    background: rgba(0, 0, 0, 0.69);
    pointer-events: all;

    .review-modal {
      max-height: 435px;
      height: 100%;
      visibility: visible;
    }
  }

  .review-form__rating {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 28px;
  }

  .review-form {
    margin-top: 16px;
  }

  textarea {
    width: 100%;
    height: 100px;
    border: 2px solid #DDE5EA;
    padding: 10px 12px;
    border-radius: 8px;
  }

  input {
    width: 100% !important;
    border: 2px solid #DDE5EA !important;
    padding: 10px 12px !important;
    border-radius: 8px !important;
    box-sizing: border-box !important;
  }

  .input-field {
    margin-top: 0 !important;
  }

  .review-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
  }

  .btn {
    width: 100%;
    max-width: 310px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    background: #3E94F8;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
    color: #fff;
    border-radius: 8px;
    margin: 0 auto 20px;
  }
}

.cookie {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  width: 100%;
  max-height: 80vh; // Limit to 80% of viewport height
  visibility: hidden;
  pointer-events: none;
  background: #fff;
  transition: .3s;
  border-radius: 30px 30px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;

  p {
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 20px 0;
    line-height: 1.5;
  }

  button {
    width: 100%;
    max-width: 310px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    background: #3E94F8;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
    color: #fff;
    border-radius: 8px;
    margin: 0 auto;
    font-size: 14px;
    padding: 10px;
  }

  &.active {
    visibility: visible;
    pointer-events: all;
  }

  @include r($md) {
    max-width: 100%;
    border: 0;
    border-top: 2px solid lightgray;
    border-radius: 0;

    p,
    button {
      max-width: 420px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (max-width: 480px) {
  .cookie {
    p {
      font-size: 14px;
    }

    button {
      font-size: 12px;
      height: 40px;
    }
  }
}

@media (max-height: 400px) {
  .cookie {
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;

    p {
      margin-right: 15px;
      margin-bottom: 0;
      text-align: left;
    }

    button {
      flex-shrink: 0;
    }
  }
}

.cookie-bg {
  position: fixed;
  inset: 0;
  background: rgba(#000, 0.5);
  z-index: 10;
}

/*AudioTopBlock*/
.audioDivAudio {
  position: absolute;
  background: white;
  padding: 3px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  z-index: 1;
  top: 70px;
  left: 110px;
  width: calc(100vw - 135px);
  border-radius: 8px;
  max-height: 55vh;
}

.audioDivAudio .btn {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 36px;
  height: 36px;
}

.gap-10 {
  gap: 10px;
}

.audioDivName {
  font-size: 18px;
  padding: 5px 20px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.topAudio {
  width: calc(100vw - 250px);
}

.topAudio div audio {
  width: calc(100vw - 250px);
  height: 36px;
}

.d-flex {
  display: flex;
}

.topAudio__img {
  text-align: center;
}

.topAudio__img img {
  margin: 20px auto;
  margin-top: 0;
  max-width: calc(100vw - 150px);
  max-height: calc(100vh - 490px);
}

.audioDiv__description {
  padding: 10px 20px 10px;
}

.audioDiv__body {
  max-height: calc(100vh - 320px);
  overflow-y: auto;
  transition: 2s;
}

@media only screen and (min-width: 601px) {
  .audioDivAudio {
    top: 78px;
  }
}