.loading-block {
  font-weight: normal;
  font-size: 14px;
  line-height: 1.46;
  text-align: center;
  color: #fff;
  position: fixed;
  height: 100svh;
  width: 100svw;
  inset: 0;
  z-index: 10;
  background: rgba(5, 27, 53, 0.91);
  backdrop-filter: blur(4px);

  .loader-text {
    color: #fff;
    text-align: center;
    font-size: 20px;
    max-width: 310px;
    margin: 80px auto 0;
  }
}

.loading-block__pic {
  width: 70px;
  height: 70px;
  margin: 0 auto 16px;
}

.loading-block__big {
  margin: 0 0 8px;
  font-weight: bold;
  font-size: 20px;
  color: #fff;
}

.loading-block__actions {
  margin-top: 24px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 32px;
  gap: 5px;
  left: calc((100svw - 70px) / 2);
  top: calc((100svh - 32px) / 2);
  position: absolute;
}

.loader > * {
  flex: 0 0 auto;
  width: 8px;
  height: 8px;
  background: #FFBC7B;
  border-radius: 4px;
  backface-visibility: hidden;
  animation: loader 1.5s linear infinite;
}

.loader > *:nth-child(1) {
  animation-delay: 0s;
}

.loader > *:nth-child(2) {
  animation-delay: 0.2s;
}

.loader > *:nth-child(3) {
  animation-delay: 0.4s;
}

.loader > *:nth-child(4) {
  animation-delay: 0.6s;
}

.loader > *:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes loader {
  0% {
    height: 8px;
  }

  20% {
    height: 32px;
  }

  40% {
    height: 8px;
  }
}

@keyframes loader {
  0% {
    height: 8px;
  }

  20% {
    height: 32px;
  }

  40% {
    height: 8px;
  }
}
