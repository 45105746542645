@import 'utils/index';
@import 'react-toastify/dist/ReactToastify.css';
@import 'layout/general';
@import 'layout/fonts';
@import 'elements/typography';
@import 'elements/layout';
@import 'elements/loader';
@import 'elements/landing';
@import 'elements/shared';

//in Samsung, prevent scrolling to be treated as
//page reload request accidentally 
html,
body {
    overscroll-behavior-y: contain;
}

//Same for pinching gestures
.map {
    touch-action: pan-x pan-y;
}

#root {
    touch-action: none;
}