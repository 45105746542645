.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 10px 15px;
  height: 48px;


  >a {
    display: flex;
    height: 24px;
    align-items: flex-end;
    /* Align the logo to the bottom */
    margin-bottom: -2px;
    /* Slight negative margin to adjust baseline */
  }

  &__burger {
    display: none;
    padding: 0 12px;
  }

  &__menu {
    flex: 0 0 auto;
  }

  &__nav {
    display: flex;
    align-items: center;

    .search-bar-button {
      color: #1f95a9;
    }

    input.search-bar {
      position: absolute;
      top: 60px;
      right: 10px;
      z-index: 10;
      border-radius: 8px;
      box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
      width: 240px;
      background-color: white;
      padding: 5px 5px 5px 5px;
      height: 25px;
    }

    div.search-results {
      position: absolute;
      top: 100px;
      background-color: white;
      width: 250px;
      right: 10px;
      z-index: 10;
      border-radius: 8px;
      box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
      max-height: 400px;
      overflow-y: scroll;
    }

    div.search-results > p {
      color: #000;
      padding-left: 10px;
    }

    div.search-result {
      color: #000;
      height: 55px;
      padding: 3px 5px 3px 5px;
      max-width: 240px;
      line-height: 1;
      white-space: nowrap;
      // overflow: hidden !important;
      overflow-x: hidden;
      // text-overflow: ellipsis;
    }

    div.search-result:hover {
      background-color: #ffd196;
    }

    div.search-result-icon {
      display: inline-block;
    }

    div.search-result-icon > img {
      width: 35px;
    }

    div.search-result-name {
      // text-align: center;
      height: 25px;
      display: inline-block;
      max-width: 190px;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }


    .burger_btn {
      background: transparent;
      margin-left: 20px;
      padding: 4px;
    }

    .filter_description {
      position: absolute;
      background: #fff;
      color: #000;
      top: 60px;
      right: 180px;
      z-index: 10;
      padding: 20px 10px 10px 10px;
      border-radius: 8px;
      box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
      max-width: 200px;
      height: 430px;
    }

    .filter_description_content {
      line-height: 20px;
      font-size: 12px;
    }

    .filter_description_content > p {
      padding-bottom: 10px;
    }
    .filter_description_content > p:last-child {
      padding-bottom: 0px;
    }

    .filter_modal {
      position: absolute;
      background: #fff;
      color: #000;
      top: 60px;
      right: 10px;
      z-index: 10;
      padding: 20px 10px 20px 10px;
      border-radius: 8px;
      box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
      max-width: 270px;
      font-size: 12px;
      height: 430px;

      .checkBox {
        gap: 5px;
        margin-bottom: 15px;
      }

      .category__image {
        width: 35px;
      }

      .apply {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        background: #3E94F8;
        font-weight: 600;
        font-size: 18px;
        box-shadow: 0 4px 4px 0 rgba(97, 97, 97, 0.53);
        color: #fff;
        text-transform: uppercase;
        border-radius: 6px;
      }
    }

    .nav__menu {
      position: absolute;
      background: #fff;
      color: #000;
      top: 60px;
      right: 10px;
      z-index: 3001;
      padding: 12px 16px;
      border-radius: 8px;
      box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);

      a {
        color: #000;
        display: flex;
        height: 24px;
        align-items: center;
        margin-bottom: 16px;
        transition: .15s;
        font-size: 18px;
        font-weight: 400;

        &:hover {
          color: #FD8108;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__nav-action,
  &__nav .MuiSelect-select {
    display: flex;
    align-items: center;

    .material-icons {
      height: 16px;
      margin-right: 6px;
      font-size: 16px;
    }
  }

  &__actions {
    padding-right: 20px;
  }
}

@include r($md) {
  .header {
    &__burger {
      display: block;
    }

    &__menu {
      a {
        padding: 0 10px;
      }
    }

    &__nav {}

    &__actions {
      padding-right: 16px;
    }
  }
}

.modal--payment {
  background: transparent;
  box-shadow: none;
  height: 300px;
  overflow-x: hidden;
  width: 100%;
}

.modal--payment .special {
  // top: 70px;
  margin: auto;
  // display: block;
}

.i-choose-this-free-for-you *, *::before, *::after {
  -webkit-box-sizing:unset;
}

.special *, *::before, *::after {
  -webkit-box-sizing:unset;
}

.special-continue {
  position: absolute;
  width: 426px;
  color: black;
  border-radius: 10px 10px 0 0;
  border: solid 1px black;
  border-bottom: 0px;
  height: 38px;
  background-color: white;
  top: -22px;
}
.special-continue p {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  color: black;
  font-size: 26px;
  line-height: 26px;
  margin-left: 10px;
  margin-top: 7px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.special {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  position: relative;
}
.special-div {
  display: flex;
  width: 426px;
}
.special-bar {
  display: flex;
  width: 100%;
  background-color: #3072cb;
  z-index: -1;
  border-radius: 10px;
  border: solid 1px white;
  height: 78px;
  margin-top: 10px;
  // padding-top: 10px;
}
.special-offer {
  width: 105px;
}
.special-offer p {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-size: 34px;
  line-height: 34px;
  color: white;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 8px;
  margin-right: 5px;
}
.special-text {
  width: 210px;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.special-text p {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-size: 20px;
  line-height: 22px;
  color: white;
  margin: 0px;
}
.special-button-overlay {
  width: 426px;
  position: absolute;
}
.not-now-button-overlay {
  width: 426px;
  position: absolute;
}

.special-button-div, .not-now-button-div {
  width: 110px;
  position: absolute;
  right: 0px;
  // top: 10px;
}
.not-now-button-div {
  top: 110px;
}
.special-button-div a {
  text-decoration: none;
}
.not-now-button-div a {
  text-decoration: none;
}
.special-button,  .not-now-button {
  border-radius: 50%;
  background-color: #00aded;
  width: 100px;
  height: 100px;
  align-content: center;
  text-align: center;
}
.not-now-button {
  background-color: white;
}

.special-button span, .not-now-button span {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  color: white;
  text-decoration: none;
  font-size: 28px;
  line-height: 28px;
  font-weight: 500;
}

.not-now-button span {
  color: #00aded;
}

.special-button-circle, .not-now-button-circle {
  border: solid 1px white;
  position: absolute;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  left: 4px;
  top: 4px;
}

.not-now-button-circle { 
  border: solid 1px #00aded;
}
@media (max-width: 500px) {
  .special-div {
    width: 330px;
  }
  .special-bar {
    height: 58px;
  }
  .special-offer {
    width: 80px;
  }
  .special-offer p {
    font-size: 24px;
    line-height: 24px;
    color: white;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 3px;
  }
  .special-text {
    width: 150px;
    margin-left: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .special-text p {
    font-size: 16px;
    line-height: 18px;
  }
  .special-button-overlay, .not-now-button-overlay {
    width: 330px;
    position: absolute;
  }
  .special-button-div, .not-now-button-div {
    width: 90px;
    position: absolute;
    right: 0px;
  }
  .not-now-button-div {
    top: 90px;
  }
  .special-button, .not-now-button {
    border-radius: 50%;
    background-color: #00aded;
    width: 80px;
    height: 80px;
    align-content: center;
    text-align: center;
  }

  .not-now-button {
    background-color: white;
  }

  .special-button span, .not-now-button span {
    color: white;
    text-decoration: none;
    font-size: 22px;
    line-height: 22px;
    font-weight: 500;
  }
  .not-now-button span {
    color: #00aded;
  }
  .special-button-circle, .not-now-button-circle {
    border: solid 1px white;
    position: absolute;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    left: 4px;
    top: 4px;
  } 
  .not-now-button-circle {
    border: solid 1px #00aded;
  }
  .special-continue {
    width: 326px;
  }       
} /*media max-width:500px*/
.i-choose-this-free-for-you {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  position: relative;
}
.i-choose-this-free-for-you-div {
  display: flex;
  width: 426px;
}
.i-choose-this-free-for-you-bar {
  display: flex;
  width: 100%;
  background-color: #3072cb;
  z-index: -1;
  border-radius: 10px;
  border: solid 1px white;
  height: 78px;
  margin-top: 10px;
}
.i-choose-this-free-for-you-button-overlay {
  width: 426px;
  position: absolute;
}
.i-choose-this-free-for-you-button-div {
  width: 110px;
  position: absolute;
  right: 0px;
}
.i-choose-this-free-for-you-button-div a {
  text-decoration: none;
}
.i-choose-this-free-for-you-button {
  border-radius: 50%;
  background-color: #00aded;
  width: 100px;
  height: 100px;
  align-content: center;
  text-align: center;
}
.i-choose-this-free-for-you-button span {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  color: white;
  text-decoration: none;
  font-size: 25px;
  line-height: 25px;
  font-weight: 500;
}      
.i-choose-this-free-for-you-bar span {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-size: 34px;
  line-height: 34px;
  color: white;
  margin-left: 60px;
  margin-top: 22px;
  margin-bottom: 22px;
  margin-right: 5px;
}
.i-choose-this-free-for-you-button-circle {
  border: solid 1px white;
  position: absolute;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  left: 4px;
  top: 4px;
}
@media (max-width: 500px) {
  .i-choose-this-free-for-you-div {
    width: 330px;
  }
  .i-choose-this-free-for-you-bar {
    height: 58px;
  }
  .i-choose-this-free-for-you-bar span {
    font-family: "Fira Sans Extra Condensed", sans-serif;
    font-size: 34px;
    line-height: 34px;
    color: white;
    margin-left: 35px;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-right: 5px;
  }
  .i-choose-this-free-for-you-button-overlay {
    width: 330px;
    position: absolute;
  }
  .i-choose-this-free-for-you-button-div {
    width: 90px;
    position: absolute;
    right: 0px;
  }
  .i-choose-this-free-for-you-button {
    border-radius: 50%;
    background-color: #00aded;
    width: 80px;
    height: 80px;
    align-content: center;
    text-align: center;
  }
  .i-choose-this-free-for-you-button span {
    color: white;
    text-decoration: none;
    font-size: 22px;
    line-height: 22px;
    font-weight: 500;
  }
  .i-choose-this-free-for-you-button-circle {
    border: solid 1px white;
    position: absolute;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    left: 4px;
    top: 4px;
  }        
} /*media max-width:500px*/

// .special {
//   margin-top: 30px;
//   margin-bottom: 20px;
//   display: flex;
//   justify-content: center;
// }
// .special-continue {
//   background-color: white;
//   position: absolute;
//   width: 426px;
//   color: black;
//   border-radius: 10px 10px 0 0;
//   border: solid 1px black;
//   border-bottom: 0px;
//   height: 38px;
// }
// .special-continue p {
//   font-family: "Fira Sans Extra Condensed", sans-serif;
//   color: black;
//   font-size: 26px;
//   line-height: 26px;
//   margin-left: 10px;
//   margin-top: 7px;
//   margin-bottom: 5px;
//   margin-right: 5px;
// }
// .special-div {
//   display: flex;
//   width: 426px;
// }
// .special-bar {
//   display: flex;
//   width: 100%;
//   background-color: #3072cb;
//   z-index: -1;
//   border-radius: 0 0 10px 10px;
//   border: solid 1px white;
//   height: 78px;
//   margin-top: 38px;
// }
// .special-offer {
//   width: 105px;
// }
// .special-offer p {
//   font-family: "Fira Sans Extra Condensed", sans-serif;
//   font-size: 34px;
//   line-height: 34px;
//   color: white;
//   margin-left: 10px;
//   margin-top: 5px;
//   margin-bottom: 8px;
//   margin-right: 5px;
// }
// .special-text {
//   width: 210px;
//   margin-left: 10px;
//   margin-top: 5px;
//   margin-bottom: 5px;
// }
// .special-text p {
//   font-family: "Fira Sans Extra Condensed", sans-serif;
//   font-size: 20px;
//   line-height: 22px;
//   color: white;
//   margin: 0px;
// }
// .special-button-overlay {
//   width: 426px;
//   position: absolute;
// }
// .special-button-div {
//   width: 110px;
//   position: absolute;
//   right: 0px;
//   top: 30px;
// }
// .special-button-div a {
//   text-decoration: none;
// }
// .special-button {
//   border-radius: 50%;
//   background-color: #00aded;
//   width: 100px;
//   height: 100px;
//   align-content: center;
//   text-align: center;
// }
// .special-button-circle {
//   border: solid 1px white;
//   position: absolute;
//   border-radius: 50%;
//   width: 90px;
//   height: 90px;
//   left: 6px;
//   top: 6px;
// }
// .special-button span {
//   font-family: "Fira Sans Extra Condensed", sans-serif;
//   color: white;
//   text-decoration: none;
//   font-size: 28px;
//   line-height: 28px;
//   font-weight: 500;
// }
// @media (max-width: 500px) {
//   .special-continue {
//     width: 330px !important;
//   }
//   .special-div {
//     width: 330px;
//   }
//   .special-bar {
//     height: 58px;
//   }
//   .special-offer {
//     width: 80px;
//   }
//   .special-offer p {
//     font-size: 24px;
//     line-height: 24px;
//     color: white;
//     margin-left: 5px;
//     margin-top: 5px;
//     margin-bottom: 5px;
//     margin-right: 3px;
//   }
//   .special-text {
//     width: 150px;
//     margin-left: 2px;
//     margin-top: 5px;
//     margin-bottom: 5px;
//   }
//   .special-text p {
//     font-size: 16px;
//     line-height: 18px;
//   }
//   .special-button-overlay {
//     width: 330px;
//     position: absolute;
//   }
//   .special-button-div {
//     width: 90px;
//     position: absolute;
//     right: 0px;
//   }
//   .special-button {
//     border-radius: 50%;
//     background-color: #00aded;
//     width: 80px;
//     height: 80px;
//     align-content: center;
//     text-align: center;
//   }
//   .special-button-circle {
//     width: 70px;
//     height: 70px;
//   }
//   .special-button span {
//     color: white;
//     text-decoration: none;
//     font-size: 22px;
//     line-height: 22px;
//     font-weight: 500;
//   }
// } /*media max-width:500px*/

.sidenav {
  pointer-events: none;
  transition: transform 0.3s ease-in-out;

  &._active {
    display: block;
    opacity: 1;
    transform: translateX(0%);
    pointer-events: all;
  }
}

.sidenav-overlay {
  &._active {
    display: block;
    opacity: 1;
  }
}

// modal
.modal.active {
  display: block;
  top: 10%;
  z-index: 1099;
  opacity: 1;
  transform: scaleX(1) scaleY(1);
}

.modal-overlay.active {
  display: block;
  z-index: 1002;
  opacity: 0.5;
}

.payment-modal {
  &__actions {
    .btn {
      margin-right: 20px;
    }
  }
}

.btn._accent {
  background: tomato;
}

// forms
.ui-form {

  .input-field,
  .ui-group {
    margin-top: 24px;
  }
}

.ui-group {
  margin-top: 20px;

  label {
    display: inline-block;
    margin-bottom: 12px;
    font-size: 1rem;
  }
}

.review-form {
  max-width: 420px;
  margin: 32px auto 0;

  &__rating {
    margin-bottom: 48px;
  }

  &__action {
    display: flex;
    justify-content: center;
  }
}

.d-none {
  display: none;
}

.position-relative {
  position: relative;
}

.settingsTopModal {
  width: 530px;
  position: absolute;
  background: white;
  border: 1px solid #ededed;
  border-radius: 10px;
  font-size: 16px;
  line-height: normal;
  top: -425px;
  overflow: hidden;
  right: 0;

  &__header {
    background: rgb(150, 194, 222);
    font-size: 16px;
    color: white;
    padding: 3px 5px;
  }

  &__closeButton {
    position: absolute;
    right: 5px;
    cursor: pointer;
    top: 2px;
  }

  &__closeButton:hover {
    color: red;
  }

  &__body {
    padding: 10px;
    /*background: #ededed;*/
  }

  &__gridGroup {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__gridGroup fieldset {
    width: 100%;
  }

  &__gridGroup legend {
    width: 100%;
    padding: 5px 10px;
    background: #ededed;
    font-weight: bold;
  }
}

.settings-cta {
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 540px) {
  .settingsTopModal {
    width: 90vw;

    &__gridGroup {
      grid-template-columns: none;

    }
  }
}

// Assistent
.assistent_btn {
  background: #d58010 !important;
  position: fixed;
  z-index: 100;
  left: 50px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.assistent_closeBg {
  position: fixed;
  width: 100svw;
  height: 100svh;
  top: 0;
  left: 0;
  z-index: 100;
  background: #000;
  opacity: 0.5;
}

.assistent_modal {
  position: fixed;
  z-index: 110;
  width: 100svw;
  height: 100svh;
  transition: .3s;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .modal_content {
    width: 400px;
    padding: 20px;
    border-radius: 12px;
    background: #fff;
    position: relative;
    z-index: 110;
    text-align: center;

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 120%;
    }

    .content_listenBtn {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      background: #224a77;
      margin: 30px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .5s;

      &.listening {
        background: red;
      }
    }
  }
}

/*
li:has(> .settingsTopModal)::before, li:has(> .settingsTopModal)::after {
  content: '';
  position: absolute;
  left: 20px;
  bottom: 55px;
  border: 10px solid transparent;
  border-top: 10px solid white;
 }
 li:has(> .settingsTopModal)::after {
  border-top: 10px solid white;
  bottom: 55px;
 }
 */