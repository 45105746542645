* {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

html {
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  max-width: 100%;
  min-width: 320px;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 1;
  color: #2e2b3b;
  background: #fff !important;
  touch-action: pan-y;
  text-size-adjust: none;

  &.js-locked {
    overflow: hidden;
  }
}

#react-sheet-slide > div > div {
  --width: min(100%, 500px);
}

a {
  text-decoration: none;
  color: inherit;
  text-decoration-skip-ink: none;
}
